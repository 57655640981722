table {
  /* border-spacing: 10px 0; */
  font-family: "Roboto", sans-serif;
  table-layout: fixed;
  width: 100%;
  font-size: 12px;
  position: relative;
  border-collapse: collapse;
}

thead {
  background: #ffff;
  margin: 0;
  top: 0;
  position: sticky;
  z-index: 10;
}

.dark-theme thead {
  background: #323743;
}

thead th {
  height: 45px;
}

th {
  /* border-bottom: 1px solid lightgray;
      border-right: 1px solid lightgray; */
  padding: 2px 4px;
  text-align: left;
  /* border-right: 1px solid rgba(199, 204, 208, 0.5); */
  /* border-left: 1px solid rgba(199, 204, 208, 0.5); */
}

[dir="rtl"] th {
  text-align: right;
}

/* [dir="rtl"] th:first-child {
  padding-right: 24px;
}

[dir="ltr"] th:first-child {
  padding-left: 24px;
} */

th .cursor-pointer:hover {
  cursor: pointer;
}

.table-header {
  display: contents;
  width: max-content;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.table-header span {
  text-transform: uppercase;
  padding-left: 10px;
}

tr {
  border-bottom: 1px solid #e9eaed;
  line-height: 14px;
  height: 60px;
}

td {
  /* border-inline: 1px solid rgba(199, 204, 208, 0.5); */
  padding: 10px;
  overflow: auto;
}

/* [dir="ltr"] td:first-child {
  padding-left: 24px;
}

[dir="rtl"] td:first-child {
  padding-right: 24px;
} */

.p-2 {
  height: 100%;
}

.container {
  height: inherit;
  overflow: auto;
}

.homepage-grid .container {
  height: calc(100% - 14px);
}

.dark-theme tr {
  border-bottom: 1px solid #505054;
}

thead {
  color: #7b809a;
  border-bottom: 1px solid rgba(199, 204, 208, 0.5);
}

/* border for sticky header */

table thead tr {
  border-top: none !important;
  /* border-bottom: none !important; */
  /* box-shadow: inset 0 1px 0 #e9eaed, inset 0 -1px 0 #e9eaed; */
}

.dark-theme thead tr {
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: inset 0 1px 0 #505054, inset 0 -1px 0 #505054;
}

/* and one small fix for weird firefox behavior, described in https://stackoverflow.com/questions/7517127/ */

table thead tr {
  background-clip: padding-box;
}

.striped:nth-child(odd) {
  /* background-color: rgba(246, 248, 250, 0.7); */
}

.cell-invisible-edit {
  background-color: #F6F8FA;
}

.cell-text-limited {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cell-text-limited:hover {
  overflow: visible;
  white-space: initial;
  text-overflow: initial;
  word-wrap: break-word;
}

.column-eye-icon {
  padding-left: 4px;
  padding-right: 4px;
}
.status_container {
  padding: 4px 8px;
  border-radius: 8px;
  text-align: center;
}
