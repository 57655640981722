body,
html,
button {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.7px;
}

button {
  letter-spacing: inherit;
}

.Toastify__toast-body {
  white-space: pre-line;
  color: white;
  text-align: right;
  padding-right: 30px !important;
}

.Toastify__toast-body .Toastify__toast-icon {
  display: none;
}

.Toastify__toast-container {
  width: unset!important;
}

.Toastify__close-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: white !important;
  opacity: 1 !important;
}

.Toastify__toast--success {
  background-color: #202658 !important;
  width: unset;
}

.Toastify__toast--error {
  background-color: #ec2531 !important;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

div.react-datetimerange-picker__wrapper {
  display: none;
}

.MuiTooltip-tooltipPlacementTop {
  max-width: 400px!important;
}

.react-datetimerange-picker__calendar .react-calendar {
  border: none;
}

.react-code-input {
  display: inline-flex!important;
}